input#website { 
    display: none; 
}
label {
    font-weight: 700;
}

.form-group {
    text-align: left;
}
div.container.contact-form {
    width: 90%;
}

