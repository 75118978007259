@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

nav.footer {
    height: auto !important;
    width: 100%;
    background-color: #1c131f;
    padding: 2.5rem;
    justify-content: flex-end !important;
}
nav.footer a {
	text-decoration:none;
	font-weight: bold;
  color: #fefefe;
  padding: .5rem 2.25rem;
}
nav.footer h6 {
  font-size: 1rem; 
  font-weight: normal;
  color: #fefefe;
  padding: 1rem 3rem 0 0;
}
nav.footer a:hover:not(.navbar-brand) {
    color:#fefefe !important;
    border-bottom: none;
}
.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
  width: 30vw;
}
.navbar-nav {
  justify-content: flex-end!important;
}
.footer .footer-center {
  text-align: center;
}
.footer .footer-right {
  text-align: right;
}
/* social media icons */
div.socialmediaicons {
  text-align: center;
  margin: 0 auto;
}
div.socialmediaicons a.twitter, div.socialmediaicons a.facebook, div.socialmediaicons a.pinterest, 
div.socialmediaicons a.etsy, div.socialmediaicons a.spoonflower, div.socialmediaicons a.instagram {
	display:inline-flex;
	text-align: center;
	width:65px;  
	height:65px;
}
div.socialmediaicons a span { height: 1px; width: 1px; position: absolute; overflow: hidden; top: -10px; }
div.socialmediaicons a.twitter {
	background: url('../social_media_icons/twitter.png') 100% 100% no-repeat;
}
div.socialmediaicons a:hover.twitter {
	background: url('../social_media_icons/twitter_rollover.png') 100% 100% no-repeat;
}
div.socialmediaicons a.facebook {
	background: url('../social_media_icons/facebook.png') 100% 100% no-repeat;
  padding-left: 0;
}
div.socialmediaicons a:hover.facebook {
	background: url('../social_media_icons/facebook_rollover.png') 100% 100% no-repeat;
}
div.socialmediaicons a.pinterest {
	background: url('../social_media_icons/pinterest.png') 100% 100% no-repeat;
}
div.socialmediaicons a:hover.pinterest {
	background: url('../social_media_icons/pinterest_rollover.png') 100% 100% no-repeat;
}
div.socialmediaicons a.instagram {
	background: url('../social_media_icons/instagram.png') 100% 100% no-repeat;
}
div.socialmediaicons a:hover.instagram {
	background: url('../social_media_icons/instagram_rollover.png') 100% 100% no-repeat;
}

div.socialmediaicons a.spoonflower {
	background: url('../social_media_icons/spoonflower.png') 100% 100% no-repeat;
}
div.socialmediaicons a:hover.spoonflower {
	background: url('../social_media_icons/spoonflower_rollover.png') 100% 100% no-repeat;
}
/* Newsletter Sign Up */
div.newsletter {
  margin: 1rem auto;
  width: 80%;
}
div.newsletter h6 {
  padding: 0 !important;
  margin-bottom: 2rem;
}
div.newsletter a.button.small {
  border: 2px #8DC63F solid !important;
  border-radius: 10px;
  padding: .75rem 1rem;
  font-weight: 600;
  color: #8DC63F;
  font-size: 1rem;
  margin: 1rem auto;
} 
@media (max-width: 1025px) {
  .footer.navbar-nav  {
    display: inline-block!important;
  }
}
@media (max-width: 991px) {
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center!important;
  }
  nav.footer h6 {
    padding: .25rem;
  }
  .navbar-nav {
    justify-content: center!important;
    text-align: center!important;
    padding: .25rem;
  }
  div.socialmediaicons {
    text-align: center!important;
    justify-content: center!important;
    padding: 0;
    margin-left: 0;
  }
  .display4 {
    font-size: 2.5rem;
  }
}
  @media (max-width: 767px) {
    nav.footer a {
      padding: 1rem 0;
    }
  }
  @media (max-width: 353px) {
    #top-logo {
      text-align: left!important;
      justify-content: flex-start!important;
      height: 60px;
    }
  }
  @media (max-width: 299px) {
  .navbar-toggler-icon {
    display: block;
  }
  nav {
    background-color: #FFF5CA;
    height: 115px;
    justify-content: center!important;
  }
  .carousel {
    margin-top: 5rem;
  }
}
