@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

.carousel-item a.hover, .lead.a.hover {
    color: #ffffff;
}
.main-section {
    text-align: center;
    margin: 3rem 2rem 1rem 2rem;
}
div.container-fluid.home {
    padding: 0 !important;
}
div.container-fluid.home p.full-width-grid, div.container-fluid.home div.full-width-grid {
    grid-column: 1 / 13;
}
header {
    align-content: center !important;
    background-color: #8f8f8f;
    background-image: linear-gradient(#979797, #9669A4);
    }

header.home-grid-container {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    justify-content: center;
    align-items: stretch;
    grid-row: auto;
    }
header.home-grid-container div.hero-content {
    z-index: 100;
    text-align: center;
    color: #fff;
    grid-column: 2 / 7;
    padding: 1.5rem;
    align-self: center;
}
header.home-grid-container div.hero-image {
    grid-column: 9 / 12;
}
header h1 {
    font-family: 'Raleway', sans-serif !important;
    color: #fff !important;
    font-weight: 900;
    text-shadow: none;
    -webkit-text-stroke: 2px #000000 !important;
}
header div.hero-content p.hero-text {
    font-size: 2.5em;
}

header .btn.btn-hero, .btn.btn-email {
    margin: 2rem .5rem;
    border-radius: 10px;
    color: #5b4363 !important;
    border: 2px #5b4363 solid !important;
    font-size: 1.5rem;
    font-weight: 800;
    text-align: center;
    opacity: 1.0;
    margin-bottom: 1rem;
    background-color: #FFF5CA;
}
.btn.btn-email {
    margin: .5rem;
}
header .btn.btn-hero:hover {
  color: #ffffff !important;
  background-color: #8DC63F !important;
    border: 2px #8DC63F solid !important;
  }
div#email .full-width-grid {
    font-size: 2em;
    padding: 2rem;
    line-height: 3rem;
    vertical-align: middle;
    flex-flow: row wrap;
    justify-self: center;
  }

#email .btn.btn-email {
    justify-self: center;
    align-self: center;
}
div#email p.full-width-grid span {
    height: 36px;
    justify-self: center;
  }
h3.valueProps {
    margin: 0;
    padding: .5em;
    color: #ffffff !important;
    font-weight: 900;
    font-size: 3rem;
}
div#testimonials {
    align-content: center !important;
    background-color: #8f8f8f;
    background-image: linear-gradient(#979797, #9669A4);
    padding: 3rem 2rem;
}
div#testimonials h6 {
    font-weight: 700;
    color: #5b4363;
    font-size: 1.25rem;
}
div#testimonials h5 {
    font-family: 'Raleway', sans-serif !important;
    font-weight: 900;
    font-size: 3rem;
    color: #FFF5CA !important;
}
div#testimonials-headline {
    background-color: #5b4363;
    padding: .75rem .25rem;
    margin: 2rem auto;
    border-radius:50px;
    opacity: .9;
    max-width: 1400px;
    text-align: center;
}
.testimonial-card {
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-radius: 15px;
    opacity: .9;
    display: inline-block;
    padding: 2.5rem;
    margin: 2rem;
    width: 20vw;
    height: auto;
    vertical-align: top;
}
.testimonial-card.card-1 {
    padding: 4rem 2.5rem;
}
.testimonial-card.card-3 {
    padding: 5.5rem 2.5rem;
}
div#commissions .btn.btn-primary {
    padding: .75rem 1rem;
    width: 30vw;
    margin: 0 auto;
}
div#commissions {
    background-color: #FFF5CA;
    padding: 1em;
}
div#stakes {
    padding: 2em 1em;
}
div#commissions h4 {
    color: #1c131f !important;
    font-size: 2rem;
}
div#commissions a:not(p.more-info a) {
    font-size: 2rem !important;
}
#step-1, #step-2, #step-3, #step-4 {
    display: inline-block;
    width: 15vw;
    padding: 2rem;
    vertical-align: top;
}
div#value-props {
    padding-bottom: 2em;
    justify-content: center;
    align-content: center;
    background-color: #5b4363;
}

button.valueProps {
    display: inline-flexbox;
    position: relative;
    vertical-align: middle;
    margin: .75em;
    padding: 0.67em;
    border: 2px solid #FFF;
    overflow: hidden;
    text-decoration: none;
    text-align: center;
    font-size: 1.5em;
    outline: none;
    color: #FFF;
    background: transparent;
    width: 17.1875rem;
    height: 8.125rem;
}
button.valueProps span {
    -webkit-transition: 0.6s;
    -moz-transition: 0.6s;
    -o-transition: 0.6s;
    transition: 0.6s;
    -webkit-transition-delay: 0.2s;
    -moz-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

button.valueProps:before,
button.valueProps:after {
    content: '';
    position: absolute;
    top: 0.67em;
    left: 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    -webkit-transition: .4s,opacity .6s;
    -moz-transition: .4s,opacity .6s;
    -o-transition: .4s,opacity .6s;
    transition: .4s,opacity .6s;
}

/* :before */

button.valueProps:before {
    content: attr(data-hover);
    -webkit-transform: translate(-150%,0);
    -moz-transform: translate(-150%,0);
    -ms-transform: translate(-150%,0);
    -o-transform: translate(-150%,0);
    transform: translate(-150%,0);
}

/* :after */

.valueProps:after {
    content: attr(data-active);
    -webkit-transform: translate(150%,0);
    -moz-transform: translate(150%,0);
    -ms-transform: translate(150%,0);
    -o-transform: translate(150%,0);
    transform: translate(150%,0);
}

/* Span on :hover and :active */

button.valueProps:hover span,
button.valueProps:active span {
    opacity: 0;
    -webkit-transform: scale(0.3);
    -moz-transform: scale(0.3);
    -ms-transform: scale(0.3);
    -o-transform: scale(0.3);
    transform: scale(0.3);
}

/*  
    We show :before pseudo-element on :hover 
    and :after pseudo-element on :active 
*/

button.valueProps:hover:before,
button.valueProps:active:after {
    opacity: 1;
    -webkit-transform: translate(0,0);
    -moz-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0);
    -webkit-transition-delay: .4s;
    -moz-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
}

/* 
  We hide :before pseudo-element on :active
*/

button.valueProps:active:before {
    -webkit-transform: translate(-150%,0);
    -moz-transform: translate(-150%,0);
    -ms-transform: translate(-150%,0);
    -o-transform: translate(-150%,0);
    transform: translate(-150%,0);
    -webkit-transition-delay: 0s;
    -moz-transition-delay: 0s;
    -o-transition-delay: 0s;
    transition-delay: 0s;
}
p.more-info, p.more-info a, div#commissions h5 {
    font-size: 1.75rem;
}
@media screen and (max-width: 2909px) {
    .testimonial-card.card-1 {
        padding: 4.125rem 2.5rem;
    }
    .testimonial-card.card-3 {
        padding: 4.75rem 2.5rem;
    }
}

@media screen and (max-width: 2473px) {
    .testimonial-card.card-1 {
        padding: 4rem 2.5rem;
    }
    .testimonial-card.card-3 {
        padding: 5.5rem 2.5rem;
    }
}
@media screen and (max-width: 2073px) {
    .testimonial-card.card-1 {
        padding: 5.3rem 2.5rem;
    }
    .testimonial-card.card-3 {
        padding: 6.75rem 2.5rem;
    }
}
@media screen and (max-width: 2026px) {
    .testimonial-card.card-1 {
        padding: 4rem 2.5rem;
    }
}
@media screen and (max-width: 1868px) {
    div#commissions .btn.btn-primary {
        width: 40vw;
    }     
    .testimonial-card {
        padding: 2.5rem;
        margin: .5rem;
        width: 28vw;
    }
    .testimonial-card.card-1 {
        padding: 4rem 2.5rem;
    }
    .testimonial-card.card-3 {
        padding: 7rem 2.5rem;
    } 
    div#email p.full-width-grid {
        display: inline-block;
      }
    
    div#email p.full-width-grid span {
        display: inline-block;
      }
}
@media screen and (max-width: 1856px) {
    .testimonial-card.card-1 {
        padding: 4.5rem 2.5rem;
    }
    .testimonial-card.card-3 {
        padding: 5.2rem 2.5rem;
    }
}
@media (max-width: 1690px) {
    .testimonial-card.card-1 {
        padding: 4rem 2.5rem;
    }
    .testimonial-card.card-3 {
        padding: 5.5rem 2.5rem;
    }
}
@media (max-width: 1622px) {
    .testimonial-card.card-1 {
        padding: 4.5rem 2.5rem;
    }
    .testimonial-card.card-3 {
        padding: 6rem 2.5rem;
    }
    header.grid-container, div#value-props {
        padding-top: 1.5em;
    }
}
@media screen and (max-width: 1480px) {
    header h1 {
        font-size: 4.8em !important;
    }
    #step-1, #step-2, #step-3, #step-4 {
        width: 30vw;
    }
    div#commissions .btn.btn-primary {
        width: 50vw;
    }
}
@media (max-width: 1432px) {
    .testimonial-card.card-1 {
        padding: 4.75rem 2.5rem;
    }
    .testimonial-card.card-3 {
        padding: 7rem 2.5rem;
    }
}
@media (max-width: 1349px) {
    .testimonial-card.card-1 {
        padding: 5.5rem 2.5rem;
    }
    header div.hero-content p.hero-text {
        font-size: 2.25em;
    }
    header h1 {
        font-size: 4.25em !important;
    }
}
@media screen and (max-width: 1242px) {
    div#commissions .btn.btn-primary {
        width: 65vw;
    }
    div#testimonials h5 {
        font-size: 2.75rem;
    }
    div#testimonials-headline {
        padding: 1.25rem;
    }
    header.home-grid-container div.hero-content {
        padding-top: 2rem;
    }
    header h1 {
        font-size: 3.5em !important;
    }
    header div.hero-content p.hero-text {
        font-size: 2em;
    }
    .testimonial-card.card-3 {
        padding: 7.75rem 2.5rem;
    }
}
@media screen and (max-width: 1145px) {
    header .btn.btn-hero, .btn.btn-email {
        margin: .5rem;
    }
    .testimonial-card.card-3 {
        padding: 8.5rem 2.5rem;
    }
}
@media screen and (max-width: 991px) {
    header h1 {
        font-size: 3.2em !important;
    }
    header div.hero-content p.hero-text {
        font-size: 1.5em;
    }
    header .btn.btn-primary {
        margin: 1rem;
    }
    div#commissions .btn.btn-primary {
        width: 75vw;
    }
}
@media screen and (max-width: 975px) {
    .testimonial-card.card-1 {
        padding: 4.75rem 2.5rem;
    }
    .testimonial-card.card-3 {
        padding: 8.5rem 2.5rem;
    }
    header h1 {
        font-size: 3.125em !important;
    }
    header div.hero-content p.hero-text {
        font-size: 1.35em;
    }
}
@media screen and (max-width: 932px) {
    .testimonial-card {
        display: block;
        width: 70vw;
        height: auto;
        margin: 1rem auto;
        padding: 2rem;
    }
    .testimonial-card.card-1, .testimonial-card.card-2, .testimonial-card.card-3 {
        padding: 2rem;
    }  
    header.home-grid-container div.hero-content {
        padding-top: 5rem;
    }
    div.hero-image img {
        max-width: 475px;
        padding-top: 9rem;
    }
    header.home-grid-container div.hero-content {
        padding-right: .5rem;
    }
}
@media screen and (max-width: 800px) {
    header .btn.btn-primary {
        margin: .8rem;
    }
    #step-1, #step-2, #step-3, #step-4 {
        width: 80vw;
        padding: 1rem;
    }
    div#value-props a.valueProps {
        font-size: 1.2rem;
    }    
}
@media screen and (max-width: 768px) {

    p.more-info, p.more-info a {
        font-size: 1.5rem;
    }
    button.valueProps {
        display: inline-block;
        text-align: center;
        height: 5.875rem;
        padding: 1rem;
    }
    header h1 {
        font-size: 2.75em !important;
    }
    header div.hero-content p.hero-text {
        font-size: 1.8em;
    }
    header.home-grid-container {
        display: flex;
        flex-flow: row wrap;
        align-self: center;
    }
    div#testimonials h5 {
        font-size: 2rem;
    }
    div.hero-image img {
        padding-top: 0;
    }
}
@media screen and (max-width: 576px) {
    header h1 {
        font-size: 2.5em !important;
    }
    h3.valueProps {
        font-size: 2rem !important;
    }
    header .btn.btn-primary {
        margin: .7rem;
        font-size: 1.75rem !important;
    }
    div#value-props a.valueProps {
        font-size: 1rem;
    }
    div#commissions .btn.btn-primary {
        margin: 0 auto;
        padding: .5rem;
        font-size: 1.5rem !important;
        text-align: center;
        width: 70vw;
    }
    #step-1, #step-2, #step-3, #step-4 {
        width: 70vw;
        margin: 0 auto;
    }
    button.valueProps {
        width: 60%;
        margin: .75rem;
    }
    div.hero-image img {
        max-width: 300px;
    }
    div#email p.full-width-grid, p.more-info {
        font-size: 1.5em;
    }
}
@media screen and (max-width: 450px) {
    header h1 {
        font-size: 2.35em !important;
    }
    header div.hero-content p.hero-text {
        font-size: 1.35em;
    }
    button.valueProps {
        height: 5rem;
    }
}
@media (max-width: 414px) {
    header h1 {
        font-size: 2.25em !important;
    }
    header .btn.btn-primary {
        margin: .5rem;
        font-size: 1.5rem !important;
    }
    .navbar-toggler-icon {
        padding-top: 0;
    }
    div#value-props a.valueProps {
        font-size: .8rem;
    }
    div.container-fluid.home {
        padding: 2rem 1rem;
    }
    div#email p.full-width-grid, p.more-info {
        font-size: 1.25em;
    }
    div#stakes h2 {
        font-size: 1.5rem !important;
    }
    header.home-grid-container div.hero-content, div.valueProps {
        padding: 5rem 1rem 1rem 1rem;
    }
}

@media screen and (max-width: 368px) {
    header h1 {
        font-size: 2.25em !important;
    }
    header .btn.btn-primary {
        margin: .25rem;
        font-size: 1rem !important;
    }
}
