.card {
    display: inline-block !important;
    margin: 1rem !important;
    vertical-align: top !important;
    width: 40% !important;
}
div#tt2021Posts, div#vinylSwatches, div#decalsCustom, div#decalsGeek, div#decalsHTV, div#paperpaintings, div#paintings, div#sculpture, div#prints, div#covertGeek, div#reclaimedMatter {
  background-color: #FFF5CA;
  padding: 1em;
}
div#tt2020Posts {
  background-color: #c0c0c0;
  padding: 2em;
}
div.ttItem, div.decalItem, div.swatch {
  display: inline-block;
  align-items: center;
  margin: 1rem;
}
div.decalItem {
  vertical-align: middle;
}
p.artCaption {
  display: block;
  padding-top: .5rem;
  font-size: .75rem;
  font-weight: 700;
}
a p.artCaption {
  color: #5b4363;
}
a:hover p.artCaption {
  color: #4d662a;
}
div.swatch {
  border: #000 1px solid;
  background-color: #FFF;
  font-size: .75rem;
  font-weight: 600;
}
div#decalGallery .full-width-grid, div#gelPlateGallery .full-width-grid, div#paintingGallery .full-width-grid, div#sculptureGallery .full-width-grid,div#printsGallery .full-width-grid, div#covertGeekGallery .full-width-grid, div#reclaimedMatterGallery .full-width-grid, div#tt2021Posts.full-width-grid, div#tt2020Posts.full-width-grid {
  grid-column: 1 / 13;
  justify-self: center;
  align-items: center;
  padding: 1rem;
  width: 80vw;
}
.overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  background: rgba(255, 245, 202, 0.5);
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.overlay-item {
  position: relative;
  margin: .5rem;
}
.overlay-item .buy-overlay {
  height: auto;
  padding: 1rem .5rem;
}
.text-overlay {
  color: #5b4363;
  font-weight: 900;
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
/* Tile Gallery */
.tile {
	width: 300px; 
	vertical-align: middle;
	display: inline-block;
	margin: 1%;
    padding: 0 1% 0 .5%;
    background-color: #fff;
	line-height: 325px;
}
.tile img.logotile, .tile img {
    vertical-align: middle; 
    text-align: center;
}
.image-set a {
    margin: 1%;
}
.box {
  display: inline-block;
  padding: 1%;
}
.boxInner img {
  width: 100%;
  height: auto;
  max-width: 300px;
}
.boxInner .titleBox  {
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  color: #FFF;
  padding: 10px;
  text-align: center;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
body.no-touch .boxInner:hover .titleBox, body.touch .boxInner.touchFocus .titleBox {
  margin-bottom: 0;
}

/* Custom Lightbox */
#root {
    text-align: center;
    font-family: 'Raleway', sans-serif;
  }
  
  .image-card {
	width: 300px; 
	vertical-align: middle;
	display: inline-block;
	margin: 1%;
    padding: 0 1% 0 .5%;
  }
  
  #lightbox-img {
    height: 80vh;
    max-width: 90vw;
    object-fit: cover;
  }
  
  #lightbox {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  button {
    color: white;
    border: 2px solid #9669A4;
    background-color: #9669A4;
    font-size: 1.2rem;
  }
  
  a {
    color: #9669A4;
  }
  
  h1 {
    color: #9669A4;
  }
  p.card-text {
    margin-bottom: 1.5rem;
    font-size: .9rem;
  }
  
  img:hover, button:hover {
    cursor: pointer;
  }
  
  body {
    background-color: rgba(196, 155, 237, 0.45);
  }

  div#shopInfo, div#secondGallery, div#thirdGallery, div#fourthGallery {
    display: block !important;
    margin: 1em auto;
    clear: both;
  }
  .clearfix:after { 
    content: "."; 
    visibility: hidden; 
    display: block; 
    height: 0; 
    clear: both;
 }
@media screen and (max-width: 1199px) {
    .card {
        width: 35%;
    }
    div.card-body h2 {
      font-size: 2rem;
    }
    div.card-body h2.jewelry-card {
      line-height: 1.26;
    }
}
@media screen and (max-width: 991px) {
  div.card-body h2 {
    font-size: 1.5rem;
  }
  div.card-body h2.jewelry-card {
    line-height: 1.5;
  }
}
@media screen and (max-width: 768px) {
    .card {
        margin: 1rem;
        text-align: center;
        width: 80%  !important;
    }
    div.card-body h2 {
      font-size: 2rem;
    }
}
@media screen and (max-width: 451px) {
  div#decalGallery .full-width-grid, div#gelPlateGallery .full-width-grid, div#paintingGallery .full-width-grid, div#sculptureGallery .full-width-grid,div#printsGallery .full-width-grid, div#covertGeekGallery .full-width-grid, div#reclaimedMatterGallery .full-width-grid, div#tt2021Posts.full-width-grid, div#tt2020Posts.full-width-grid  {
    padding: .5rem;
    width: 100vw;
  }
}