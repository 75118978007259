@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700;800;900&family=Sarina&display=swap');
body {
  padding-top: 5rem;
  font-family: 'Raleway', sans-serif !important;
  background-color: #fefefe;
  font-weight: 400;
  margin: 0;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: center;
  align-items: stretch;
  grid-row: auto;
}
.griditem {
  display: flex;
  align-items: center;
  justify-content: center;
}
.full-width-grid {
  grid-column: 1 / 12;
  justify-self: center;
  align-items: center;
  padding: 1rem;
  width: 80vw;
}
.main-section {
  padding: 3rem 1.5rem;
  margin: 1rem auto;
  text-align: center;
  display: block;
  max-width: 1140px;
}
.space-above {
	margin-top: 2rem;
}
.space-below {
	margin-bottom: 2rem;
}
.main-section a, div#commissions a {
	text-decoration:none;
	font-weight: bold;
  padding: 0;
  color: #9669A4 !important;
}
.main-section a:hover, div.full-width-grid:hover p:hover a:hover {
  background-color: none;
  color: #222121;
}
strong.fa-stack-1x.text-primary-top {
  color: #ffffff !important;
  font-size: 2.75rem;
}
.fa {
  color: #5b4363 !important;
}
.fa-stack-1x {
  line-height: 2;
}
.btn.btn-primary.disabled {
  opacity: 0.5;
}
.btn.btn-primary {
  color: #5b4363 !important;
  background-color: #FFF5CA !important;
  border-radius: 10px;
  border: 4px #9669A4 solid !important;
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
  opacity: 1.0;
  margin-bottom: 1rem;
  padding: .5rem 1rem;
}
.btn.btn-primary:hover {
  color: #ffffff !important;
  background-color: #8DC63F !important;
  border: 4px #8DC63F solid !important;
}
img.featuredImage {
  border-radius: 10px;
  border: 4px #9669A4 solid;
  width: 100%;
}
img.bioImage {
  border-radius: 10px;
  border: 4px #9669A4 solid;
  float: left;
  margin: 4rem 2rem 1rem 0;
}
img.reclaimedMatterLogo {
  max-width: 25rem;
}
div hr {
  border-top: 4px #9669A4 solid !important;
  padding: 1rem 0;
}
.terms {
  text-align: justify;
}
.container {
  margin: 1rem auto;
}
.gimmeSpace {
  display: block !important;
}
.h1,.h5, h1, h5 {
  font-family: 'Sarina', cursive !important;
  color: #5b4363 !important;
}
.h2, .h3, .h4, h2, h3, h4 {
  font-family: 'Raleway', sans-serif !important;
}
h1 {
  font-size: 5rem !important;
  text-shadow: 2px 2px #000000;
}
h2, .h2 {
  text-align: center;
  font-weight: 900 !important;
  color: #4d662a !important;
  margin-top: 1rem;
  /*-webkit-text-stroke: 1.5px black !important;
  text-shadow: 2px 2px #000000;*/
   font-size: 2.5rem;
}
h3 {
  font-weight: 700 !important;
  margin-top: 1rem;
}
h4 {
  font-weight: 600 !important;
  margin-top: 1rem;
  color: #9669A4 !important;
}
ul.navbar-nav.header li.nav-item a:hover {
  color: #ffffff !important;
}
h5, .h5 {
  font-size: 1.75rem;
}
.lead {
  font-weight: 600 !important;
}
ul#page-list {
  list-style-type: none;
}
ul#page-list li {
  display: inline-block;
  margin: 1.5em;
}
ul#page-list li a {
  padding: 1em;
}
@media screen and (max-width: 1200px) {
  .navbar {
    justify-content: center !important;
  }
}
@media screen and (max-width: 991px) {
  .navbar-toggler-icon {
    font-size: 2rem !important;
    color: #5b4363 !important;
    padding-top: .5rem;
  }
  ul.navbar-nav.header li.nav-item {
    background-color: #ffffff !important;
  }
 .navbar-brand {
     margin: 0 .25rem !important;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 3.25rem !important;
    padding-top: 1rem;
  }
  .main-section {
    padding: 1rem;
    margin: 1rem .25rem .25rem .25rem;
  }
  .carousel {
    margin-top: 2rem !important;
  }
  img.featuredImage {
    text-align: center;
    width: 100%;
  }
  img.bioImage {
    float: none;
    margin: 1rem .25rem .25rem .25rem;
    text-align: center;
    width: 90%;
  }
  .rc-anchor-container {
      text-align: center;
      margin: .5rem auto;
  }
  .full-width-grid {
    padding: .5rem;
  }
}
h3 a {
  font-size: 1.75rem;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 3rem !important;
  }
  h2 {
    font-size: 2rem !important;
  }
  h3 {
    font-size: 1.5rem !important;
  }
  .lead {
    font-size: 1.1rem !important;
  }
  .navbar-toggler-icon {
    display: block;
    text-align: center !important;
  }
  .navbar-toggler {
    text-align: center;
    margin: .5rem auto !important;
}
  nav {
    height: 150px !important;
  }
  .main-section:not(.main-section.home) {
    margin: 4rem .25rem .25rem .25rem;
  }
  ul#page-list, ul#page-list li a {
    text-align: center;
    margin-left: -1em;
  }
  ul#page-list li {
    display: block;
    margin: 1.5em auto;
  }
}

@media screen and (max-width: 475px) {
  div.main-section h3 a {
     font-size: 1.35rem; 
  }
  img.reclaimedMatterLogo {
    max-width: 20rem;
  }
}

@media (max-width: 368px) {
  h1 {
    font-size: 2.2rem !important;
  }
  h2 {
    font-size: 1.75rem !important;
  }
  h3 {
    font-size: 1.25rem !important;
  }
  .btn.btn-primary {
    font-size: 1.75rem !important;
  }
  img.reclaimedMatterLogo {
    max-width: 19.375rem;
  }
}
