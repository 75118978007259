@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
nav {
    background-color: #FFF5CA;
    height: 110px;
    justify-content: flex-end!important;
}
.navbar-nav {
    text-align: right!important;
    padding-right: 3rem;
    justify-content: flex-end!important;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: .5rem 1rem;
}
a {
	  text-decoration:none;
	  font-weight: bold;
    color: #161616;
    padding: 0 2.5rem;
    font-size: 1.2rem;
}
a.active.nav-link:not(.main-section):not(div#commissions):not(div#instagramFollowCard.full-width-grid a) {
    border-bottom: 1px #5b4363 solid;
}
a:hover:not(.navbar-brand):not(.main-section):not(div#commissions):not(div#instagramFollowCard.full-width-grid a):not(nav.footer a) {
	background-color: #5b4363;
    border-bottom: 1px #5b4363 solid;
    color:#fefefe !important;
}
.navbar.header a.nav-link {
  color: #5b4363 !important;
}
.navbar-toggler {
    border:0;
  }
  
.navbar-toggler-icon {
    display:inline-block;
    font:normal normal normal 14px/1 FontAwesome;
    font-size:inherit;
    text-rendering:auto;
    background-image:none;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
  }
  
.navbar-toggler-icon:before {
    content:"\f0c9";
  }
  
.navbar-toggler[aria-expanded=true] .navbar-toggler-icon:before {
    content:"\f07c";
  }
.navbar-toggler ul li {
    background: rgba(248, 250, 248, 0.9); 
  }
li.nav-item a.active.nav-link {
    border-bottom: none !important;
}
.dropdown:hover>.dropdown-menu {
  display: block;
}
.button.small {
  border: 2px #5b4363 solid;
  border-radius: 10px;
  padding: .75rem 1rem;
  font-weight: 600;
  color: #5b4363;
  font-size: 1rem;
}
a.button.small.d-none.d-md-block {
  margin-right: 1.5rem;
} 
.button.small:hover {
  color: #202020 !important;
  background-color: #8DC63F !important;
  border: 2px #8DC63F solid !important;
  text-decoration: none;
}
@media (max-width: 768px) {
    .navbar-brand {
      text-align: center;
      margin-right: 0;
    }
  }